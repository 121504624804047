<template>
  <div class="u__background--white">
    <Content>
      <FlexContainer>
        <FlexItem padding="normal" :grow="true">
          <h2>Privacy Policy</h2>
          <p />
        </FlexItem>
      </FlexContainer>
    </Content>
  </div>
</template>

<script>
export default {}
</script>
